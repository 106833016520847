* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Reem Kufi", sans-serif !important;
}

a {
    text-decoration: none;
    color: inherit;
}

a:visited {
    color: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

.App {
    min-height: 100vh;
    width: 85vw;
    max-width: 1900px;
    margin: 0rem auto;
}

body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    background-color: #EFECEC;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
}

.navbar-menu-container {
    display: none;
}

.navbar-links-container {
    display: flex;
    flex-direction: row;
}

.navbar-links-container a {
    margin-right: 10px;
    text-decoration: none;
    color: black;
    /*font-size: 1.1rem;
        font-weight: 600;*/
}

.navbar-links-container-icon {
    flex-direction: column !important;
    align-items: end !important;
    align-content: end;
    text-align: end;
}

.primary-button {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
}

.navbar-cart-icon {
    font-size: 1.15rem;
}

.navbar-header-text {
    font-size: 2rem;
}


.primary-button:hover {
    background-color: rgb(234, 234, 234);
}

.navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
}

.home-banner-container {
    position: relative;
    display: flex;
    padding-top: 3rem;
}

.home-bannerImage-container {
    position: absolute;
    top: -100px;
    right: -170px;
    z-index: -2;
    max-width: 700px;
}

.home-image-section {
    max-width: 600px;
    flex: 1;
}

.home-image-analytic-container {
    display: flex;
    justify-content: center;
}

.home-image-analytic-item {
    text-align: center;
    margin-right: 20px;
}


.home-image-analytic-text {
    margin-top: 10px;
    /* Espaço entre a imagem e o texto */
}


.home-image-analytic {
    width: 350px;
    height: 350px;
    margin-right: 10px;
}

.home-image-analytic:last-child {
    margin-right: 0;
}

.home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.services-image-section {
    max-width: 600px;
    flex: 1;
}

.services-image-container {
    display: flex;
    justify-content: center;
}

.services-image-item {
    text-align: center;
    margin-right: 20px;
}

.services-image-text {
    margin-top: 15px;
    /* Espaço entre a imagem e o texto */
    max-width: 200px;
    text-align: left !important;
}


.services-image-subheader {
    text-align: center !important;
    font-size: large;
    color: #747A27
}


.services-image {
    width: auto;
    height: 128px;
    margin-right: 10px;
}

.services-image:last-child {
    margin-right: 0;
}

.primary-heading {
    font-size: clamp(2rem, 5vw, 4rem);
    color: #4c4c4c;
    /* line-height: 5rem; */
    max-width: 600px;
}

.primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 500px;
    color: #6a6a6a;
    margin: 1.5rem 0rem;
}

.secondary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #494949;
    margin: 1.5rem 0rem;
}

.secondary-button {
    padding: 1rem 2.5rem;
    background-color: #fe9e0d;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondary-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
}

.secondary-button:hover {
    background-color: #e48f0f;
}

.about-image {
    width: 512px;
}

.about-image-section {
    max-width: 600px;
    flex: 1;
}

.about-image-container {
    display: flex;
    justify-content: center;
}

.about-image-item {
    text-align: center;
    margin-right: 20px;
}

.HowItWorks-image {
    width: 512px;
}

.HowItWorks-image-section {
    max-width: 600px;
    flex: 1;
}

.HowItWorks-image-container {
    display: flex;
    justify-content: center;
}

.HowItWorks-image-item {
    width: 300px;
    text-align: center;
    margin-right: 10px;
}

.primary-subheading {
    font-weight: 700;
    color: #9a7334;
    font-size: 1.8rem;
}

.about-buttons-container {
    margin-top: 2rem;
    display: flex;
}

.watch-video-button {
    margin-left: 2rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: #484848;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watch-video-button svg {
    font-size: 3rem;
    margin-right: 1rem;
}

.contact-page-wrapper h1 {
    max-width: 900px !important;
}

.contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 6rem 0rem;
}

.contact-form-container {
    background-color: white;
    max-width: 700px;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5rem;
}

.contact-form-container input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
}

.footer-wrapper {
    margin: 6rem 0rem;
    margin-top: 10rem;
}

.footer-logo-container {
    max-width: 110px;
}

.footer-icons svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    color: #585858;
}

.footer-section-one {
    display: flex;
    flex-direction: column;
}

.footer-section-two {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: -145px;
}

.footer-section-columns {
    display: flex;
    flex-direction: column;
    min-width: 190px;
}

.footer-section-columns span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
}

.footer-section-copyright {
    position: absolute;
    bottom: -1;
    right: 0;
}

@media (max-width: 1000px) {
    .navbar-links-container a {
        margin-right: 1rem;
        font-size: 1rem;
    }

    .primary-button {
        font-size: 1rem;
    }

    .home-bannerImage-container {
        max-width: 600px;
    }
}

@media (max-width: 800px) {
    .nav-logo-container {
        max-width: 140px;
    }

    .navbar-links-container {
        display: none;
    }

    .navbar-menu-container {
        display: flex;
    }

    .home-bannerImage-container {
        max-width: 450px;
    }

    .home-banner-container,
    .about-section-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .about-section-container {
        flex-direction: column;
    }

    .home-image-section,
    .about-section-image-container {
        width: 100%;
        max-width: 400px;
    }

    .primary-heading {
        text-align: center;
        max-width: 90%;
    }

    .primary-text {
        text-align: center;
        max-width: 80%;
    }

    .home-text-section,
    .about-section-text-container {
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
    }

    .secondary-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
        cursor: pointer;
    }

    .about-section-container {
        margin-top: 5rem !important;
    }

    .about-buttons-container {
        justify-content: center;
        flex-direction: column;
    }

    .primary-subheading {
        text-align: center;
    }

    .watch-video-button {
        margin-left: 0rem !important;
        margin-top: 1rem;
        font-size: 1rem !important;
    }

    .watch-video-button svg {
        margin-right: 0.5rem !important;
    }

    .about-section-image-container {
        margin-right: 0rem !important;
    }

    .contact-form-container {
        padding: 0.5rem !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1rem !important;
    }

    .contact-form-container input {
        font-size: 1rem !important;
        max-width: 100%;
        padding: 0.5rem 1rem !important;
        margin-bottom: 0.8rem !important;
        text-align: center;
    }

    .footer-wrapper {
        flex-direction: column;
    }

    .footer-section-two {
        flex-direction: column;
        margin-top: 2rem;
    }

    .footer-section-columns {
        margin: 1rem 0rem;
    }

    .App {
        max-width: 95vw;
    }
}

@media (max-width: 600px) {

    .home-bannerImage-container,
    .about-background-image-container {
        display: none;
    }
}


.whatsapp-text {
    font-size: x-large;
    color: #4c4c4c;
    max-width: 600px;
    text-align: center;
    display: block;
}

.contact-page-map {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-page-map img {
    width: 50%;
}

.contact-page-map iframe {
    border: none;
    width: 50%;
    height: 450px;
}